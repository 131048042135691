import React from 'react';

const PackageIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='109' height='109' viewBox='0 0 109 109' fill='none'>
        <path
            d='M84.7566 33.4L89.4966 31.1429L84.7566 28.8857L55.5748 14.9896L54.5 14.4778L53.4252 14.9896L24.2434 28.8857L19.5034 31.1429L24.2434 33.4L53.4252 47.2961L54.5 47.8079L55.5748 47.2961L84.7566 33.4ZM95.9286 43.7483V39.7888L92.3537 41.4911L61.2109 56.3211L59.7857 56.9997V58.5782V88.0457V92.0052L63.3605 90.3029L94.5034 75.473L95.9286 74.7943V73.2158V43.7483ZM49.2143 58.5782V56.9997L47.7891 56.3211L16.6463 41.4911L13.0714 39.7888V43.7483V73.2158V74.7943L14.4966 75.473L45.6395 90.3029L49.2143 92.0052V88.0457V58.5782ZM54.5 2.76898L106.5 27.5309V81.4691L54.5 106.231L2.5 81.4691V27.5309L54.5 2.76898Z'
            fill='white'
            stroke='black'
            strokeWidth='5'
        />
    </svg>
);

export default PackageIcon;
