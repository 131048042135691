export function base64ToFile(base64String: string, filename: string): File {
    const arr = base64String.split(',');
    // @ts-ignore
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    // eslint-disable-next-line no-plusplus
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
}

export const getMobileDeviceName = (): string => {
    const { userAgent } = navigator;

    // iOS detection
    if (/iPad|iPhone|iPod/.test(userAgent)) {
        return 'iPhone';
    }

    // Android detection
    if (/android/i.test(userAgent)) {
        return 'Android';
    }

    return 'unknown';
};

export const formatPackageTitle = (count: number): string => {
    const ending = count === 1 || (count % 10 === 1 && count % 100 !== 11) ? 'Package' : 'Packages';
    return `${count} ${ending}`;
};
