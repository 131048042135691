import React from 'react';

const LockIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='84' height='109' viewBox='0 0 84 109' fill='none'>
        <rect x='3' y='38' width='77.3509' height='68' stroke='white' strokeWidth='6' />
        <rect x='41.9117' y='65.3414' width='2.8' height='12.1153' stroke='white' strokeWidth='2.8' />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M28.625 5.625H58.375V20.3047H64V5.625V0H58.375H28.625H23V5.625V35.375V41H28.625H58.375H64V35.375V35.2985H58.375V35.375H28.625V5.625Z'
            fill='white'
        />
    </svg>
);

export default LockIcon;
