import {
    QRDetails,
    QRTenant,
    ReqGetQRDetails,
    ReqPinUnlock,
    ReqSendPackage,
    ReqStartVideoCall,
    ReqTerminateCall,
    StartCallDetails,
} from 'components/QRIntercom/types';
import axios from 'axios';
import queryString from 'query-string';
import { TError } from 'types/errors';

let domain = window?.location?.hostname?.includes('.swiftlane.com')
    ? 'https://admin.swiftlane.com/api/v1'
    : 'https://admin.swiftpass.io/api/v1';

domain = window?.location?.hostname?.includes('localhost') ? '/api/v1' : domain;

export const API = axios.create({
    url: domain,
    // baseURL: domain,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    },
    paramsSerializer: {
        serialize: params => {
            return queryString.stringify(params, { skipEmptyString: true, skipNull: true });
        },
    },
});

export const requestGetQRDetails = async ({ code, longitude, latitude }: ReqGetQRDetails): Promise<QRDetails> => {
    const payload = latitude && longitude ? { longitude, latitude } : undefined;
    try {
        const { data } = await API.post(`${domain}/scan-qr-code/${code}`, payload || {});
        return data.data;
    } catch (error: TError) {
        if (error.response) {
            const msg = error.response?.data?.data?.msg || error.response?.data?.message;
            throw new Error(msg);
        }
        throw new Error('Something went wrong');
    }
};

export const requestStartVideoCall = async ({
    call_type,
    tenant_id,
    longitude,
    latitude,
    files,
    fallback_call,
}: ReqStartVideoCall): Promise<StartCallDetails> => {
    const formData = new FormData();

    if (files) {
        files.forEach(file => {
            formData.append('files', file);
        });
    }
    if (tenant_id) formData.append('tenant_id', tenant_id);
    if (call_type) formData.append('call_type', call_type);
    formData.append('is_chime_supported', 'yes');

    if (fallback_call) formData.append('fallback_call', fallback_call);

    if (longitude && latitude) {
        formData.append('longitude', longitude.toString());
        formData.append('latitude', latitude.toString());
    }

    try {
        const { data } = await API.post(`${domain}/qrcodes/video-call`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return data?.data || data;
    } catch (error: TError) {
        if (error.response) {
            const msg = error.response?.data?.data?.msg || error.response?.data?.message;
            throw new Error(msg);
        }
        throw new Error('Something went wrong');
    }
};

export const requestGetQRTenants = async (): Promise<QRTenant[]> => {
    try {
        const { data } = await API.get(`${domain}/qrcodes/tenants`);
        return data.data;
    } catch (error: TError) {
        if (error.response) {
            const msg = error.response?.data?.data?.msg || error.response?.data?.message;
            throw new Error(msg);
        }
        throw new Error('Something went wrong');
    }
};

export const requestTerminateCall = async ({ room_name, room_sid, call_type }: ReqTerminateCall): Promise<null> => {
    try {
        const { data } = await API.post(`${domain}/qrcodes/video-call/terminate`, { room_name, room_sid, call_type });
        return data.data;
    } catch (error: TError) {
        if (error.response) {
            const msg = error.response?.data?.data?.msg || error.response?.data?.message;
            throw new Error(msg);
        }
        throw new Error('Something went wrong');
    }
};

export const requestPinUnlock = async ({ visitor_pin, files, longitude, latitude }: ReqPinUnlock): Promise<null> => {
    const formData = new FormData();
    if (files) {
        files.forEach(file => {
            formData.append('files', file);
        });
    }
    if (longitude && latitude) {
        formData.append('longitude', longitude.toString());
        formData.append('latitude', latitude.toString());
    }
    formData.append('visitor_pin', visitor_pin);

    try {
        const { data } = await API.post(`${domain}/qrcodes/pin-unlock`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return data.data;
    } catch (error: TError) {
        if (error.response) {
            const msg = error.response?.data?.data?.msg || error.response?.data?.message;
            throw new Error(msg);
        }
        throw new Error('Something went wrong');
    }
};

export const requestSendPackage = async ({ files, longitude, latitude }: ReqSendPackage): Promise<string> => {
    const formData = new FormData();

    if (files) {
        files.forEach(file => {
            formData.append('files', file);
        });
    }

    if (longitude && latitude) {
        formData.append('longitude', longitude.toString());
        formData.append('latitude', latitude.toString());
    }

    try {
        const { data } = await API.post(`${domain}/qrcodes/scan-package`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return data?.data || data;
    } catch (error: TError) {
        if (error.response) {
            const msg = error.response?.data?.data?.msg || error.response?.data?.message;
            throw new Error(msg);
        }
        throw new Error('Something went wrong');
    }
};
