import React, { FC } from 'react';
import Loader from 'components/QRIntercom/components/Loader';
import CameraIcon from 'components/QRIntercom/icons/Camera';
import styles from './styles.module.scss';

type Props = {
    onCallPress: () => void;
    onCancelPress: () => void;
    isLoading?: boolean;
};

const FallBakCall: FC<Props> = ({ isLoading, onCallPress, onCancelPress }) => {
    return (
        <div className={styles.root}>
            <div className={styles.content}>
                <div className={styles.message}>{`Sorry, the call wasn't answered by anyone...`}</div>
            </div>
            <footer className={styles.actions}>
                <button type='button' disabled={isLoading} onClick={onCallPress}>
                    Call Secondary Desc
                    {isLoading ? <Loader color='#fff' size={18} /> : <CameraIcon />}
                </button>
                <button type='button' disabled={isLoading} className={styles.cancel} onClick={onCancelPress}>
                    Cancel
                </button>
            </footer>
        </div>
    );
};

export default FallBakCall;
