import React, { FC } from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

type Props = {
    size?: number;
    color?: string;
    className?: string;
};

const Loader: FC<Props> = ({ className, size, color }) => {
    return (
        <div className={cn(styles.loader, className)} style={size ? { width: size, height: size } : undefined}>
            <div className={styles.spinner}>
                <div className={styles.dot} style={color ? { backgroundColor: color } : undefined} />
                <div className={styles.dot} style={color ? { backgroundColor: color } : undefined} />
                <div className={styles.dot} style={color ? { backgroundColor: color } : undefined} />
                <div className={styles.dot} style={color ? { backgroundColor: color } : undefined} />
                <div className={styles.dot} style={color ? { backgroundColor: color } : undefined} />
                <div className={styles.dot} style={color ? { backgroundColor: color } : undefined} />
                <div className={styles.dot} style={color ? { backgroundColor: color } : undefined} />
                <div className={styles.dot} style={color ? { backgroundColor: color } : undefined} />
            </div>
        </div>
    );
};

export default Loader;
