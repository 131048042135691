import React from 'react';

const ListIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='black'>
        <circle cx='1.125' cy='4.5' r='1.125' />
        <circle cx='1.125' cy='9' r='1.125' />
        <circle cx='1.125' cy='13.5' r='1.125' />
        <path d='M4.49805 4.5C4.49805 3.87868 5.00173 3.375 5.62305 3.375H16.873C17.4944 3.375 17.998 3.87868 17.998 4.5C17.998 5.12132 17.4944 5.625 16.873 5.625H5.62305C5.00173 5.625 4.49805 5.12132 4.49805 4.5Z' />
        <path d='M4.49805 9C4.49805 8.37868 5.00173 7.875 5.62305 7.875H16.873C17.4944 7.875 17.998 8.37868 17.998 9C17.998 9.62132 17.4944 10.125 16.873 10.125H5.62305C5.00173 10.125 4.49805 9.62132 4.49805 9Z' />
        <path d='M4.49805 13.5C4.49805 12.8787 5.00173 12.375 5.62305 12.375H16.873C17.4944 12.375 17.998 12.8787 17.998 13.5C17.998 14.1213 17.4944 14.625 16.873 14.625H5.62305C5.00173 14.625 4.49805 14.1213 4.49805 13.5Z' />
    </svg>
);

export default ListIcon;
