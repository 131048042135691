import React from 'react';

const ScanPackageIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='22' height='24' viewBox='0 0 22 24' fill='black'>
        <path fillRule='evenodd' clipRule='evenodd' d='M0 6V2V0H2H20H22V2V6H20V2L2 2V6H0Z' />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M22 18L22 22L22 24L20 24L2 24L0 24L1.74845e-07 22L5.24537e-07 18L2 18L2 22L20 22L20 18L22 18Z'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M18 8.33333L11 5L4 8.33333V15.6667L11 19L18 15.6667V8.33333ZM14.7481 9L11 7.21518L7.25188 9L11 10.7848L14.7481 9ZM16 10.619L12 12.5238V16.3086L16 14.4039V10.619ZM10 12.5238L6 10.619V14.4039L10 16.3086V12.5238Z'
        />
    </svg>
);

export default ScanPackageIcon;
