import React from 'react';

const CheckIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='14' height='11' viewBox='0 0 14 11' fill='white'>
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M4.49982 11L4.46453 10.9675L4.43218 11L0 6.41305L1.1243 5.24483L4.46448 8.7022L12.8767 0L14 1.17025L4.49982 11Z'
        />
    </svg>
);

export default CheckIcon;
