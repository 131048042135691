import React from 'react';
import { createRoot } from 'react-dom/client';
import QRIntercom from './components/QRIntercom';

const container = document.getElementById('root');
const root = createRoot(container as any); // eslint-disable-line

root.render(<QRIntercom />);

export function unregister() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready
            .then(registration => {
                registration.unregister();
            })
            .catch(error => {
                window?.console.error(error.message);
            });
    }
}

unregister();
