import React, { FC, useCallback, useContext, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { randInt } from 'utils/random';
import { requestSendPackage } from 'components/QRIntercom/services';
import { toast } from 'react-toastify';
import { TError } from 'types/errors';
import { useHistory } from 'react-router-dom';
import ScanPackageIcon from 'components/QRIntercom/icons/ScanPackage';
import CheckIcon from 'components/QRIntercom/icons/Check';
import PackageIcon from 'components/QRIntercom/icons/Package';
import { QRContext } from 'components/QRIntercom/context';
import styles from './styles.module.scss';
import ArrowLeftIcon from '../../icons/ArrowLeft';
import { base64ToFile, formatPackageTitle } from '../../utils';

const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: { exact: 'environment' },
};

const ScanPackage: FC = () => {
    const webcamRef = useRef<Webcam>(null);
    const { location } = useContext(QRContext);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [photo, setPhoto] = useState<string | null>(null);
    const [uploadFilesCount, setUploadFilesCount] = useState<number>(0);
    const [openUploadModal, setOpenUploadModal] = useState<boolean>(false);
    const [requestsCount, setRequestsCount] = useState<number>(0);

    const { goBack } = useHistory();

    const uploadFile = useCallback(
        async (file: File) => {
            if (!file) {
                return;
            }
            setIsLoading(true);
            try {
                setRequestsCount(prev => prev + 1);
                setUploadFilesCount(prev => prev + 1);
                await requestSendPackage({ files: [file], latitude: location?.latitude, longitude: location?.longitude });
            } catch (error: TError) {
                toast.error(error?.message || 'Something went wrong, try again.');
            } finally {
                setIsLoading(false);
                setRequestsCount(prev => prev - 1);
            }
        },
        [location],
    );

    const capture = useCallback(() => {
        const imageSrc = webcamRef.current?.getScreenshot();
        if (imageSrc) {
            setPhoto(imageSrc);
            uploadFile(base64ToFile(imageSrc, `package_${randInt(1000, 9999)}.jpeg`));
        }
    }, [uploadFile]);

    const handleUploadDonePress = useCallback(() => {
        setUploadFilesCount(0);
        setPhoto(null);
        setOpenUploadModal(false);
        setRequestsCount(0);
        goBack();
    }, [goBack]);

    return (
        <section className={styles.scanPackage}>
            <header>
                <button disabled={isLoading} type='button' onClick={goBack}>
                    <ArrowLeftIcon />
                </button>

                <p>SCAN PACKAGE</p>
                <div />
            </header>
            <div className={styles.content}>
                <div className={styles.camera}>
                    <Webcam
                        audio={false}
                        height={720}
                        ref={webcamRef}
                        screenshotFormat='image/jpeg'
                        width={1280}
                        videoConstraints={videoConstraints}
                        screenshotQuality={0.8}
                    />
                </div>
                <div className={styles.actionsBox}>
                    <div className={styles.label}>
                        {uploadFilesCount ? `${formatPackageTitle(uploadFilesCount)} Scanned` : 'Tap To Scan'}
                    </div>
                    <div className={styles.actions}>
                        <div className={styles.preview}>
                            <div className={styles.imgBox}>
                                {photo && <img src={photo} alt='preview' />}
                                {!!uploadFilesCount && <span>{uploadFilesCount}</span>}
                            </div>
                        </div>

                        <div className={styles.captureBtnBox}>
                            <button className={styles.captureBtn} type='button' onClick={capture}>
                                <ScanPackageIcon />
                            </button>
                        </div>
                        <button className={styles.submitBtn} type='button' onClick={() => setOpenUploadModal(true)} disabled={!photo}>
                            <CheckIcon />
                        </button>
                    </div>
                </div>
            </div>
            {openUploadModal && (
                <div className={styles.loading}>
                    {requestsCount > 0 && (
                        <div>
                            <p className={styles.loadingSubTitle}>Do Not CLOSE the window</p>
                            <p className={styles.loadingTitle}>
                                Uploading
                                <span className={styles.dotsLoading}>
                                    <span className={styles.dot} />
                                    <span className={styles.dot} />
                                    <span className={styles.dot} />
                                </span>
                            </p>
                        </div>
                    )}
                    {requestsCount === 0 && (
                        <div>
                            <p className={styles.loadingTitle}>
                                PACKAGE SCANS <br />
                                UPLOADED!
                            </p>
                        </div>
                    )}
                    <div className={styles.packageWrap}>
                        <div className={styles.packageBox}>
                            <PackageIcon />
                            <span>{uploadFilesCount}</span>
                        </div>
                    </div>
                    {requestsCount === 0 && (
                        <button type='button' className={styles.uploadDoneBtn} onClick={handleUploadDonePress}>
                            DONE
                        </button>
                    )}
                </div>
            )}
        </section>
    );
};

export default ScanPackage;
