import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { QRRoutes } from './routes';
import QRContextProvider from './context';
import Permissions from './pages/Permissions';
import AccessOptions from './pages/AccessOptions';
import ScanPackage from './pages/ScanPackage';
import Tenants from './pages/Tenants';
import EnterPin from './pages/EnterPin';
import './global.scss';

const QRIntercom = () => {
    return (
        <>
            <Switch>
                <Route path={QRRoutes.permissions} exact>
                    <Permissions />
                </Route>
                <Route path='/qr' exact>
                    <Permissions />
                </Route>
                <Route path={QRRoutes.accessOptions} exact>
                    <AccessOptions />
                </Route>
                <Route path={QRRoutes.callTenant} exact>
                    <Tenants />
                </Route>
                <Route path={QRRoutes.pinCode} exact>
                    <EnterPin />
                </Route>
                <Route path={QRRoutes.packageScan} exact>
                    <ScanPackage />
                </Route>
            </Switch>
        </>
    );
};

const Wrapped = () => (
    <Router>
        <QRContextProvider>
            <QRIntercom />
            <ToastContainer position='top-right' hideProgressBar />
        </QRContextProvider>
    </Router>
);

export default Wrapped;
