import React, { FC, useCallback, useContext, useMemo, useState } from 'react';
import { ICustomMap } from 'types/ICustomMap';
import { TError } from 'types/errors';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { QRRoutes } from '../../routes';
import PermissionsIcon from '../../icons/Permissions';
import styles from './styles.module.scss';
import { QRContext } from '../../context';
import Header from '../../components/Header';
import { getMobileDeviceName } from '../../utils';

const Permissions: FC = () => {
    const { qrDetails, getQRDetails, setLocation } = useContext(QRContext);
    const [showPermissionsErr, setPermissionsErr] = useState<boolean>(false);
    const [isGettingPermissions, setIsGettingPermissions] = useState<boolean>(false);

    const { push } = useHistory();

    const requestLocation = useCallback(() => {
        return new Promise<ICustomMap>((resolve, reject) => {
            const timeoutId = setTimeout(() => {
                reject(new Error('Location request timed out'));
            }, 75000); // Set timeout to 10 seconds

            const watchId = navigator.geolocation.watchPosition(
                position => {
                    clearTimeout(timeoutId);
                    navigator.geolocation.clearWatch(watchId);
                    resolve(position);
                },
                error => {
                    clearTimeout(timeoutId);
                    navigator.geolocation.clearWatch(watchId);
                    reject(error);
                },
            );
        });
    }, []);

    const requestPermissions = useCallback(async () => {
        setIsGettingPermissions(true);
        setPermissionsErr(false);
        let location: ICustomMap | null = null;

        try {
            const stream = await navigator.mediaDevices?.getUserMedia({ video: { facingMode: 'user' }, audio: true });
            // Stop the media stream
            stream.getTracks().forEach(track => track.stop()); // Stop the tracks as we only need to check the access
        } catch (error: TError) {
            window?.console.log('Error:', error?.name, error?.message);
            if (error?.name === 'NotFoundError' || error?.name === 'DevicesNotFoundError') {
                toast.error('Requested device not found');
                setPermissionsErr(true);
            } else if (error?.name === 'NotAllowedError' || error?.name === 'PermissionDeniedError') {
                toast.error('Media Permissions denied by the user');
                setPermissionsErr(true);
            } else {
                toast.error(error?.message || 'Failed to get Video/Mic permissions');
            }
            setIsGettingPermissions(false);
            setPermissionsErr(true);
            return;
        }

        try {
            if (qrDetails?.location_check) {
                location = await requestLocation();
                setLocation({ latitude: location?.coords?.latitude, longitude: location?.coords?.longitude });
            }
        } catch (error: TError) {
            toast.error(error?.message || 'Failed to get location permissions');
            setIsGettingPermissions(false);
            setPermissionsErr(true);
            return;
        }

        try {
            await getQRDetails({ latitude: location?.coords?.latitude, longitude: location?.coords?.longitude });
        } catch (error: TError) {
            setIsGettingPermissions(false);
            return;
        }

        push(QRRoutes.accessOptions);
        setIsGettingPermissions(false);
    }, [push, qrDetails, requestLocation, setLocation, getQRDetails]);

    const helpLink = useMemo(() => {
        const device = getMobileDeviceName();
        if (device === 'iPhone') return '/qr-files/iPhone.mp4';
        if (device === 'Android') return '/qr-files/Android.mp4';
        return null;
    }, []);

    return (
        <div className={styles.permissions}>
            <Header />
            {qrDetails && <p className={styles.titleTxt}>Welcome to</p>}
            <h2 className={styles.title}>{qrDetails?.site_name}</h2>
            <h5 className={styles.address}>{qrDetails?.qr_code_address || qrDetails?.address}</h5>
            <div className={styles.wrap}>
                <div>
                    <div className={styles.box}>
                        {!showPermissionsErr && <span className={styles.subBlock} />}
                        {showPermissionsErr ? (
                            <>
                                <p>We cannot start the call without location and camera/mic permissions.</p>
                                {helpLink && (
                                    <p className={styles.errTxt}>
                                        <strong>
                                            <a href={helpLink} target='_blank' rel='noreferrer'>
                                                Click here
                                            </a>
                                        </strong>
                                        &nbsp;to see how to grant those and reload the page.
                                    </p>
                                )}
                            </>
                        ) : (
                            <>
                                <div className={styles.icon}>
                                    <PermissionsIcon />
                                </div>
                                <h5>To use the video intercom</h5>
                                <ul>
                                    <li>Grant camera/mic/location permissions (required)</li>
                                    <li>We verify your location</li>
                                    <li>Select entry and start a call</li>
                                </ul>
                                <div className={styles.bottom}>
                                    <div className={styles.line}>
                                        <span />
                                    </div>
                                    <div className={styles.line}>
                                        <span />
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    {!showPermissionsErr && (
                        <div className={styles.nextBtnBox}>
                            {qrDetails && (
                                <button type='button' onClick={requestPermissions} disabled={isGettingPermissions}>
                                    {isGettingPermissions ? 'Verifying permissions...' : 'NEXT'}
                                </button>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Permissions;
