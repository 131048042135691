import React, { createContext, useMemo, useCallback, ReactNode, FC, useState, useEffect } from 'react';
import queryString from 'query-string';
import { TError } from 'types/errors';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { QRRoutes } from './routes';
import { API, requestGetQRDetails } from './services';
import { QRDetails, StartCallDetails, TLocation } from './types';

type TQRContext = {
    qrDetails?: QRDetails;
    location?: TLocation;
    isLoading: boolean;
    error?: string;
    callDetails?: StartCallDetails;
    setQrDetails: (val: QRDetails) => void;
    setLocation: (val: TLocation) => void;
    setCallDetails: (val: StartCallDetails) => void;
    getQRDetails: (loc?: TLocation) => void;
};

type Props = {
    children: ReactNode;
};

const initData: TQRContext = {} as TQRContext;

export const QRContext = createContext(initData);

const QRContextProvider: FC<Props> = ({ children }) => {
    const [qrDetails, setQrDetails] = useState<QRDetails>();
    const [error, setError] = useState<string>('');
    const [location, setLocation] = useState<TLocation>({ latitude: 0, longitude: 0 });
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [callDetails, setCallDetails] = useState<StartCallDetails>();

    const { push } = useHistory();

    const uuid = useMemo(() => {
        const query = queryString.parse(window?.location?.search);
        const key = query.uuid || localStorage.getItem('qrUUID') || null;
        localStorage.setItem('qrUUID', (key as string) || '');
        return key;
    }, []);

    const getQRDetails = useCallback(
        async (loc?: TLocation) => {
            try {
                const data = await requestGetQRDetails({
                    code: uuid as string,
                    latitude: loc?.latitude,
                    longitude: loc?.longitude,
                });
                if (data.msg) {
                    setError(data.msg);
                } else {
                    setQrDetails(data);
                }
            } catch (err: TError) {
                if (loc) {
                    toast.error(err?.message);
                } else {
                    toast.error('Error has occurred, please try to scan QR code again.');
                }
                throw err;
            } finally {
                setIsLoading(false);
            }
        },
        [uuid],
    );

    useEffect(() => {
        if (uuid) {
            getQRDetails();
        }
        if (!uuid) {
            push(QRRoutes.permissions, { replace: true });
            setIsLoading(false);
            toast.error('Please scan the QR code to proceed');
        }
    }, [getQRDetails, uuid, push]);

    useEffect(() => {
        API.interceptors.response.use(
            response => response,
            (err: TError) => {
                if (err.response?.status === 401) {
                    push(QRRoutes.permissions, { replace: true });
                    const msg = err?.response?.data?.data?.msg || err?.response?.data?.message || 'Something went wrong, please try again';
                    toast.error(msg);
                }
                return Promise.reject(err);
            },
        );
    }, [push]);

    return (
        <QRContext.Provider
            value={{
                qrDetails,
                location,
                isLoading,
                error,
                callDetails,
                setQrDetails,
                setLocation,
                setCallDetails,
                getQRDetails,
            }}
        >
            {children}
        </QRContext.Provider>
    );
};

export default QRContextProvider;
