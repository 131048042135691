import React from 'react';

const ArrowLeftIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='19' viewBox='0 0 16 19' fill='white'>
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M9.3509 0.658203L10.6061 1.92714L3.96141 8.64453L15.5657 8.64453V10.4391L3.96665 10.4391L10.6087 17.1538L9.3535 18.4227L1.82126 10.8081L1.81966 10.8097L0.564453 9.54077L9.3509 0.658203ZM3.13983 9.60322V9.47509L3.07646 9.53916L3.13983 9.60322Z'
        />
    </svg>
);

export default ArrowLeftIcon;
