import { EYesNo } from 'config/general';

export type TLocation = {
    latitude: number;
    longitude: number;
};

export type ReqGetQRDetails = {
    code: string;
    latitude?: number;
    longitude?: number;
};

export enum EOnOff {
    on = 'on',
    off = 'off',
}

export type QRDetails = {
    access_point_name: string;
    address: string;
    enable_package_scan: EOnOff;
    enable_tenant_intercom: EOnOff;
    enable_visitor_enter_pin: EOnOff;
    enable_visitor_front_desk_call: EOnOff;
    front_desk_text: string;
    qr_code_address: string | null;
    site_name: string;
    tenant_intercom_text: string;
    visitor_button_label_text: string;
    location_check: boolean;
    msg?: string;
};

export type ReqStartVideoCall = {
    tenant_id?: string;
    call_type?: 'FRONT_DESK' | 'TENANT';
    longitude: number;
    latitude: number;
    files: File[];
    fallback_call?: EYesNo;
};

export type StartCallDetails = {
    call_provider: 'TWILIO' | 'VONAGE';
    join_token: string;
    access_token: string;
    room_name: string;
    room_sid: string;
    vonage_app_id: string;
    ws_auth_token: string;
    ws_device_id: string;
    company_id_str: string;
    is_fallback_front_desk_available: boolean;
    msg?: string;
};

export type QRTenant = {
    id_str: string;
    name: string;
};

export type ReqTerminateCall = {
    room_sid: string;
    room_name: string;
    call_type: 'FRONT_DESK' | 'TENANT';
};

export type ReqPinUnlock = {
    visitor_pin: string;
    files: File[];
    latitude?: number;
    longitude?: number;
};

export type ReqSendPackage = {
    files: File[];
    latitude?: number;
    longitude?: number;
};
