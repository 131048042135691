import React, { FC } from 'react';
import Sound from 'components/common/Sound';
import LockIcon from '../../icons/Lock';
import CheckIcon from '../../icons/Check';
import doorUnlock from '../../assets/door-unlock.mp3';

import styles from './styles.module.scss';

type Props = {
    address: string;
    title: string;
};

const DoorUnlocked: FC<Props> = ({ address, title }) => {
    return (
        <div className={styles.root}>
            <div className={styles.title}>
                <p>{address}</p>
                <span>{title}</span>
            </div>
            <div className={styles.icon}>
                <LockIcon />
            </div>
            <div className={styles.unlocked}>
                DOOR UNLOCKED
                <CheckIcon />
            </div>
            <Sound audio={doorUnlock} />
        </div>
    );
};

export default DoorUnlocked;
