import React, { lazy, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { TError } from 'types/errors';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { DOOR_UNLOCK_TIMEOUT } from 'components/QRIntercom/config';
import { QRRoutes } from '../../routes';
import { requestGetQRTenants, requestStartVideoCall } from '../../services';
import { QRTenant } from '../../types';
import styles from './styles.module.scss';
import ArrowLeftIcon from '../../icons/ArrowLeft';
import SearchIcon from '../../icons/Search';
import { QRContext } from '../../context';
import CameraIcon from '../../icons/Camera';
import CapturePhoto from '../../components/CapturePhoto';
import Loader from '../../components/Loader';

const JoinCallRoom = lazy(() => import('../../components/JoinCallRoom'));

const Tenants = () => {
    const { location, setCallDetails } = useContext(QRContext);
    const [tenants, setTenants] = useState<QRTenant[]>([]);
    const [selectedTenant, setSelectedTenant] = useState<string>('');
    const [search, setSearch] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isCalling, setIsCalling] = useState<boolean>(false);
    const [showCapturePhoto, setShowCapturePhoto] = useState<boolean>(false);
    const [openCallModal, setOpenCallModal] = useState<boolean>(false);

    const { push, goBack } = useHistory();

    useEffect(() => {
        const reqTenants = async () => {
            setIsLoading(true);
            try {
                const resp = await requestGetQRTenants();
                setTenants(resp);
            } catch (err: TError) {
                // setStep(EQRSteps.permissions);
                push(QRRoutes.permissions, { replace: true });
            } finally {
                setIsLoading(false);
            }
        };

        reqTenants();
    }, [push]);

    const handleSelectTenant = useCallback(
        (id: string) => () => {
            setSelectedTenant(id);
        },
        [],
    );

    const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
    }, []);

    const reqVideoCall = useCallback(
        async (photo: File | null) => {
            setShowCapturePhoto(false);
            if (!location) {
                toast.error('Location permissions not found, please enable location permissions and try again.');
                return;
            }
            setIsCalling(true);

            if (!photo) {
                toast.error('Failed to capture photo');
                return;
            }

            try {
                // const tenantName = tenants.find(tenant => tenant.id_str === selectedTenant)?.name;
                const data = await requestStartVideoCall({
                    call_type: 'TENANT',
                    tenant_id: selectedTenant,
                    longitude: location.longitude,
                    latitude: location.latitude,
                    files: [photo],
                });
                if (data.msg) {
                    toast.error(data.msg);
                } else {
                    setCallDetails(data);
                    // setRoomLabel(tenantName || '');
                    // setIsFrontDest(false);
                    setOpenCallModal(true);
                }
            } catch (error: TError) {
                toast.error(error?.message);
            } finally {
                setIsCalling(false);
            }
        },
        [location, selectedTenant, setCallDetails],
    );

    const toggleCapturePhoto = useCallback(() => {
        setShowCapturePhoto(prev => !prev);
    }, []);

    const onEndCallPress = useCallback(() => {
        setOpenCallModal(false);
    }, [setOpenCallModal]);

    const onDoorUnlock = useCallback(() => {
        setTimeout(() => {
            goBack();
        }, DOOR_UNLOCK_TIMEOUT);
    }, [goBack]);

    const filteredTenants = tenants.filter(tenant => tenant.name.toLowerCase().includes(search.toLowerCase()));
    const roomName = useMemo(() => tenants.find(tenant => tenant.id_str === selectedTenant)?.name || '', [tenants, selectedTenant]);

    return (
        <div className={styles.root}>
            <div className={styles.searchBar}>
                <div className={styles.backBox}>
                    <button type='button' onClick={goBack}>
                        <ArrowLeftIcon />
                    </button>
                </div>
                <div className={styles.field}>
                    <input type='text' placeholder='SEARCH' onChange={handleSearch} />
                    <span>
                        <SearchIcon />
                    </span>
                </div>
            </div>
            {isLoading && (
                <div className={styles.loading}>
                    <Loader size={62} />
                </div>
            )}
            {filteredTenants?.length > 0 && (
                <ul className={styles.list}>
                    {filteredTenants.map(tenant => (
                        <li key={tenant.id_str} className={cn({ [styles.active]: selectedTenant === tenant.id_str })}>
                            <button
                                className={styles.itemBtn}
                                type='button'
                                disabled={isCalling}
                                onClick={handleSelectTenant(tenant.id_str)}
                            >
                                {tenant.name}
                            </button>
                            <button className={styles.callBtn} type='button' onClick={toggleCapturePhoto} disabled={isCalling}>
                                CALL
                                {isCalling ? <Loader size={18} /> : <CameraIcon />}
                            </button>
                        </li>
                    ))}
                </ul>
            )}
            {showCapturePhoto && <CapturePhoto onPhotoCapture={reqVideoCall} />}
            {openCallModal && (
                <JoinCallRoom roomLabel={roomName} isFrontDesc={false} onEndCallPress={onEndCallPress} onDoorUnlock={onDoorUnlock} />
            )}
        </div>
    );
};

export default Tenants;
