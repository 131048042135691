import React from 'react';

const PinIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='black'>
        <circle cx='2.8125' cy='3.9375' r='1.6875' />
        <circle cx='2.8125' cy='8.99951' r='1.6875' />
        <circle cx='9' cy='3.9375' r='1.6875' />
        <circle cx='9' cy='8.99951' r='1.6875' />
        <circle cx='9' cy='14.063' r='1.6875' />
        <circle cx='15.1855' cy='3.9375' r='1.6875' />
        <circle cx='15.1855' cy='8.99951' r='1.6875' />
    </svg>
);

export default PinIcon;
