import React from 'react';

const SearchIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='white'>
        <path
            opacity='0.5'
            d='M0.189453 14.752L3.26626 11.6834C2.39394 10.554 1.85824 9.14902 1.85824 7.60769C1.85764 3.94402 4.84229 0.967285 8.51573 0.967285C12.1892 0.967285 15.1895 3.94402 15.1895 7.62326C15.1895 11.3025 12.2048 14.2792 8.51573 14.2792C6.98513 14.2792 5.56152 13.7449 4.42918 12.8749L1.36798 15.9274L0.189453 14.752ZM13.5213 7.62326C13.5213 4.8755 11.2715 2.63166 8.51637 2.63166C5.7613 2.63166 3.51147 4.8755 3.51147 7.62326C3.51087 10.371 5.76068 12.5994 8.51576 12.5994C11.2708 12.5994 13.5213 10.371 13.5213 7.62326Z'
        />
    </svg>
);

export default SearchIcon;
