import React from 'react';

const PermissionsIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='67' height='38' viewBox='0 0 67 38' fill='none'>
        <circle cx='17' cy='19' r='17' fill='black' />
        <path
            d='M23.6667 14.8459H20.1108L19.2217 13H14.7775L13.8883 14.8459H10.3333C9.57749 14.8459 9 15.4457 9 16.2306V23.6153C9 24.3535 9.57749 25 10.3333 25H23.6667C24.4225 25 25 24.3535 25 23.6153V16.2306C25 15.4465 24.4225 14.8459 23.6667 14.8459ZM17 23.1541C15.2667 23.1541 13.8892 21.7235 13.8892 19.9234C13.8892 18.1233 15.2667 16.6928 17 16.6928C18.7333 16.6928 20.1108 18.1233 20.1108 19.9234C20.1108 21.7235 18.7333 23.1541 17 23.1541Z'
            fill='#F8F8F8'
        />
        <circle cx='48' cy='19' r='18' fill='black' stroke='white' strokeWidth='2' />
        <path
            d='M47.0236 12.0858C42.5629 12.6746 40.5285 17.9865 43.1875 21.5384C44.0259 22.6554 45.281 24.3374 46.4118 25.8518C47.2122 26.9238 48.8185 26.9211 49.6163 25.8472L50.2267 25.0254C51.1013 23.7314 53.5535 21.0263 53.806 19.4886C54.864 15.5776 51.4535 11.3415 47.0236 12.0858ZM45.4511 17.7943C45.4631 16.449 46.5968 15.3652 47.9902 15.3652C49.3845 15.3652 50.5175 16.449 50.5301 17.7943C50.5174 19.1389 49.3845 20.2228 47.9902 20.2228C46.5968 20.2228 45.463 19.139 45.4511 17.7943Z'
            fill='#F8F8F8'
        />
    </svg>
);

export default PermissionsIcon;
